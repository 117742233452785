import { Helmet } from "react-helmet";
import getDesignTokens from "./theme";
import { useMemo, useState, Suspense, Fragment } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import "moment/locale/ar";
import moment from "moment";
import { useTranslation } from "react-i18next";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import Splash, { AppLoader } from "./splash";

function App() {
  const { i18n } = useTranslation();

  const [mode, setMode] = useState(localStorage?.getItem("mode") || "light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          let mode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("mode", mode);
          return mode;
        });
      },
    }),
    []
  );

  const isRTL = i18n.language === "ar" ? "rtl" : "ltr";
  moment.locale(i18n.language);
  document.body.setAttribute("dir", isRTL);

  const theme = useMemo(() => {
    return createTheme({ ...getDesignTokens(mode), direction: isRTL });
  }, [mode, isRTL]);

  let plugins = [prefixer];
  if (isRTL === "rtl") {
    plugins.push(rtlPlugin);
  }

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: plugins,
  });

  function resetHeight() {
    // reset the body height to that of the inner browser

    document.body.style.height = window.innerHeight + "px";
    let divElement = document?.querySelector("#app");
    if (divElement) {
      divElement.style.height = window.innerHeight + "px";
    }
  }

  window.addEventListener("resize", resetHeight);

  resetHeight();

  return (
    <Fragment>
      <Helmet>
        <title>Limber X | Online paltform</title>
        <meta name="description" content={"Limber X | Online paltform"} />
        <meta property="og:title" content={"Limber X | Online paltform"} />
        <meta
          property="og:description"
          content={"Limber X | Online paltform"}
        />
        {/* <meta property="og:image" content={logoIcon} /> */}
        <meta property="og:url" content={"Limber X | Online paltform"} />
        <link rel="canonical" href={"Limber X | Online paltform"} />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Limber X Online paltform" />
        <meta property="og:image:alt" content={"Limber X | Online paltform"} />
        <meta property="fb:app_id" content="559076901495442" />
        <meta name="twitter:title" content={"Limber X | Online paltform"} />
        <meta
          name="twitter:description"
          content={"Limber X | Online paltform"}
        />
        {/* <meta name="twitter:image" content={logoIcon} /> */}
        <meta name="twitter:card" content="summary_large_image" />

        {/* <link rel="preload" as="image" href={logoIcon} /> */}
      </Helmet>
      <Suspense fallback={<AppLoader />}>
        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div id="app">
              <Suspense fallback={<AppLoader />}>
                <Splash colorMode={colorMode} mode={mode} isRTL={isRTL} />
              </Suspense>
            </div>
          </ThemeProvider>
        </CacheProvider>
      </Suspense>
    </Fragment>
  );
}

export default App;
